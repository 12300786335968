.circle {
    display: inline-block;
    background-color: orange;
    height: 15px;
    width: 15px;
    border-radius: 25px;
    -webkit-animation-name: bounce;
    -webkit-animation-duration: 1.2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: bounce;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    margin-left: 8px;
  }
  
  #container {
    height: 200px;
    margin-top: 50px;
  }
  
  #ball-1{
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  #ball-2{
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }
  #ball-3{
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  
  @-webkit-keyframes bounce {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(23px);
    }
    60% {
      transform: translateY(-25px);
    }
    80%{
      transform: translateY(0);
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(23px);
    }
    60% {
      transform: translateY(-25px);
    }
    80%{
      transform: translateY(0);
    }
  }