@charset "UTF-8";
/*
 *  Owl Carousel - Core
 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import '~@angular/cdk/overlay-prebuilt.css';
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  overflow: hidden;
}

.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #ed9720;
}

.empty-stars:before, .full-stars:before {
  content: "★ ★ ★ ★ ★";
  font-size: 12pt;
}

.empty-stars:before {
  -webkit-text-stroke: 1px #b1b1b1;
}

.full-stars:before {
  -webkit-text-stroke: 1px #ed9720;
}

/* Webkit-text-stroke is not supported on firefox or IE */
/* Firefox */
@-moz-document url-prefix() {
  .full-stars {
    color: #ed9720;
  }
}
/* IE */
.full-stars {
  color: #ed9720;
}

.circle {
  display: inline-block;
  background-color: orange;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: bounce;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  margin-left: 8px;
}

#container {
  height: 200px;
  margin-top: 50px;
}

#ball-1 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

#ball-2 {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

#ball-3 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(23px);
  }
  60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(23px);
  }
  60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}
/** Colors variables */
.sb-default .sb-wrapper {
  --sb-color: #fff;
  --sb-background: var(--button-color);
  --sb-border-radius: 4px;
  --sb-overflow: hidden;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text {
  --sb-text-padding: 0 1em 0 0;
}
.sb-default .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  filter: none;
}

body {
  font-family: "Poppins", sans-serif, arial;
  letter-spacing: 0.4px;
}

pre {
  margin: 0px;
}

html {
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.small-scroller::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.paragraph {
  font-size: 14px;
}

.search {
  width: 100%;
}

.search > input {
  font-size: 14px;
  padding: 10px 10px;
  border: 1px solid #e1e1e1;
  width: 100%;
  border-right: none;
}

.search > .lft {
  padding: 10px 10px 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: transparent;
}

.search > .rgt {
  padding: 10px 10px 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  background-color: #db823c;
}

.search > .bookmark {
  padding: 10px 10px 0px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: transparent;
  margin-left: 5px;
}

.search > .bookmark:hover {
  background-color: #d5efff;
  border: 1px solid #d5efff;
}

.accordion-button {
  font-size: 16px;
  padding: 10px;
  color: #272727 !important;
}

.accordion-button:not(.collapsed) {
  background: white;
  box-shadow: none;
  color: #0282CF;
}

hr:not([size]) {
  height: 1px;
  width: 96%;
  margin: auto;
  opacity: 0.1;
  margin-top: 10px;
}

.lang-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(150px, calc(100% / 4)), 1fr));
}

.lang-item {
  text-align: left;
  padding: 8px 0px;
}
.lang-item a {
  text-decoration: none;
  font-size: 14px;
  color: #202020;
}
.lang-item a:hover {
  color: #000;
  font-weight: 500;
}

.right-side {
  background-color: #eaeaea33 !important;
}
.right-side .blog {
  margin-top: 10px;
}
.right-side .blog .header-tree {
  background-color: #F2F2F2 !important;
  padding: 8px 5px;
}
.right-side .blog .header-tree p {
  font-size: 16px;
  text-transform: capitalize;
}

.right-side .blog:first-child {
  margin-top: 0px;
}

.content {
  overflow: hidden;
}

.offcanvas-small {
  width: 370px !important;
}

.left-side {
  background-color: #eaeaea33 !important;
}
.left-side .lang-box-list {
  margin-top: 30px;
  padding: 0px;
  background: #eaeaea33;
}
.left-side .tree {
  width: 100%;
  min-height: 500px;
}
.left-side .header-tree {
  background-color: #F2F2F2 !important;
  padding: 10px 10px;
  width: 100%;
}
.left-side .header-tree p {
  font-size: 16px;
  text-transform: capitalize;
}
.left-side .offcanvas-start {
  width: 320px !important;
  background-color: #FBFBFB;
}
.left-side .offcanvas-end {
  width: 320px !important;
}

.btn.next-prev-btn {
  padding: 6px 20px;
}

/* NgxHighligh */
.hljs-ln-n {
  padding: 0px 10px;
  background: #c9c9c914;
  text-align: center;
}

code.hljs.hljs-line-numbers {
  border: 1px solid #ececec;
  margin-top: 0px;
  padding: 0px;
}

table.hljs-ln tr:first-child .hljs-ln-numbers .hljs-ln-n {
  padding-top: 20px;
}

table.hljs-ln tr:last-child .hljs-ln-numbers .hljs-ln-n {
  padding-bottom: 20px;
}

td.hljs-ln-line.hljs-ln-code {
  padding-left: 5px;
}

/* width */
.hljs.hljs-line-numbers::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}

@media (min-width: 992px) {
  .left-side {
    max-width: 250px;
    width: 280px;
    padding: 0px;
  }

  .content {
    flex-basis: calc(100% - 525px);
  }

  .content-two {
    flex-basis: calc(100% - 250px);
  }

  .right-side {
    max-width: 270px;
    max-height: 156px;
    width: 270px;
    padding: 0px;
  }
}
.owl-prev {
  position: absolute;
  left: -25px;
  top: 45%;
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  border-radius: 50% !important;
}

.owl-next {
  position: absolute;
  right: -25px;
  top: 45%;
  width: 40px;
  height: 40px;
  font-size: 20px !important;
  border-radius: 50% !important;
}

@media (max-width: 991.98px) {
  .btn.next-prev-btn {
    font-size: 0;
    width: auto;
    padding: 6px 15px;
  }
  .btn.next-prev-btn fa-icon {
    font-size: 16px;
  }

  .owl-next {
    right: -15px;
  }

  .owl-prev {
    left: -15px;
  }
}
.owl-theme .owl-nav .disabled {
  display: none !important;
}

#tutorial .owl-theme .owl-nav .disabled {
  display: block !important;
}

#tutorial .owl-prev {
  position: absolute;
  left: 36%;
  top: -86px !important;
  width: auto !important;
  height: auto !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  background: none;
  color: #db823c;
}

.carousal-nav {
  font-size: 75px !important;
}

#tutorial .owl-next {
  position: absolute;
  right: 36%;
  top: -86px;
  width: auto !important;
  height: auto !important;
  font-size: 20px !important;
  border-radius: 50% !important;
  background: none;
  color: #db823c;
}

.owl-theme .owl-dots .owl-dot span {
  background-color: #869791;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #000000;
}

#videoTab .nav-tabs .nav-link.active {
  color: white;
  background-color: #db823c;
}

#videoTab .nav-tabs .nav-link:first-child {
  margin-left: 0px;
  margin-right: 8px;
}

#videoTab .nav-tabs .nav-link {
  color: #000;
  background-color: #eeeded;
  margin: 0px 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* fallback */
@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v84/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2) format("woff2");
}
.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.c-pointer {
  cursor: pointer;
}

.dropdown-toggle::after {
  border: none;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.videoWrapper > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
}

#contact-us-form input {
  border: 1px solid #8f8f8f !important;
  border-radius: 3px !important;
  opacity: 0.9 !important;
  font-size: 14px;
}

#contact-us-form textarea {
  border: 1px solid #8f8f8f !important;
  border-radius: 3px !important;
  opacity: 0.9 !important;
  font-size: 14px;
}

#contact-us-form select {
  border: 1px solid #8f8f8f !important;
  border-radius: 3px !important;
  opacity: 0.9 !important;
  font-size: 14px;
}

#contact-us-form input:placeholder-shown {
  font-size: 14px;
  opacity: 0.4;
}

#contact-us-form select {
  font-size: 14px;
}

#treelist {
  position: relative;
}

.selected {
  color: orange;
}

.node {
  position: absolute;
  list-style: none;
  cursor: default;
}

.span {
  color: #03a142;
}

.node span {
  margin-right: 3px;
}

.node .caret {
  font-size: 10px;
}

.code-editor .editor {
  border: 1px solid #f5f5f5;
  width: 100% !important;
  height: 100% !important;
}

.fs-7 {
  font-size: 12px;
}

.elipsis-para {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

.review-para {
  overflow-y: auto;
  height: 175px;
  line-height: 26px;
}

.hover-me:hover {
  background-color: #db823c !important;
  color: #fff !important;
}

.elipsis-1 {
  -webkit-line-clamp: 1;
}

.elipsis-2 {
  -webkit-line-clamp: 2;
}

.elipsis-3 {
  -webkit-line-clamp: 3;
}

.elipsis-4 {
  -webkit-line-clamp: 4;
}

.elipsis-5 {
  -webkit-line-clamp: 5;
}

.elipsis-10 {
  -webkit-line-clamp: 10;
}

.root1:last-child > app-curriculum .tree-line {
  display: none;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

a {
  text-decoration: none;
}

a:hover {
  color: #ec6a1f;
  text-decoration: none;
}

.technologies a:hover {
  text-decoration: none;
}

.spinner-icon {
  animation: rotating 2s linear infinite;
}

.autocomplete-container {
  box-shadow: none !important;
  position: relative;
  overflow: visible;
  height: auto !important;
  border: 1px solid #00000026;
  border-radius: 3px;
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.img {
  max-width: 100%;
  height: auto;
}

.tutorial-para {
  font-size: 1rem;
}

.tutorial-data {
  line-height: 1.8;
  word-break: break-word;
  font-family: "Open Sans", sans-serif !important;
}

.tutorial-spara {
  font-size: 15px;
  line-height: 1.7;
  word-break: break-word;
  font-family: "Open Sans", sans-serif !important;
}

.monaco-editor .cursors-layer > .cursor {
  display: none !important;
}

.next-prev-btn {
  padding: 10px 0px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #9b9b9b;
  width: 100px;
  color: #000;
}

.next-prev-btn:hover {
  background-color: #0282CF;
  border: 1px solid #0282CF;
  color: #fff;
}

.h6-para {
  font-size: 16px;
  padding-left: 20px;
  color: #1f1f1f;
  margin-bottom: 0px;
}

.h5-para {
  font-size: 18px;
  color: #202020;
  margin-bottom: 0px;
  margin-top: 0.5rem;
}

.h4-para {
  font-size: 20px;
  color: #202020;
  margin-bottom: 0px;
  margin-top: 0.5rem;
}

.h3-para {
  font-size: 22px;
  color: #202020;
  margin-bottom: 0px;
  margin-top: 0.5rem;
}

.h2-para {
  font-size: 26px;
  color: #202020;
  margin-bottom: 0px;
  margin-top: 1rem;
}

.h1-para {
  font-size: 28px;
  color: #202020;
  margin-bottom: 10px;
  margin-top: 1.5rem;
  text-align: center;
}

.quiz-list label.star {
  padding: 2px !important;
  font-size: 20px !important;
}

label.star:hover {
  transform: none !important;
}

input.star-5:checked ~ label.star:before {
  color: #ff9477 !important;
  text-shadow: none !important;
}

.material-icons {
  cursor: pointer;
}

img.success-mark {
  margin-right: 5px;
  width: 20px;
}

.price .discount {
  margin-left: 5px;
}

.breadcrumb {
  background-color: transparent !important;
}

.question-item a {
  color: black;
  text-decoration: none;
}

.question-item a:hover {
  color: #007bff;
}

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}
@media (max-width: 450px) {
  .tutorial-data {
    padding: 0px 5px;
    line-height: 1.8;
    word-break: break-word;
    font-family: "Open Sans", sans-serif !important;
  }

  .h5-para {
    font-size: 18px;
    color: #202020;
    margin-bottom: 0px;
    margin-top: 0.5rem;
    margin-left: -8px;
  }

  .h4-para {
    font-size: 20px;
    color: #202020;
    margin-bottom: 0px;
    margin-top: 0.5rem;
    margin-left: -8px;
  }

  .h3-para {
    font-size: 22px;
    color: #202020;
    margin-bottom: 0px;
    margin-top: 0.5rem;
    margin-left: -8px;
  }

  .h2-para {
    font-size: 24px;
    color: #202020;
    margin-bottom: 0px;
    margin-top: 1rem;
    margin-left: -8px;
  }
}
.share-button {
  padding: 2px;
  border-radius: 4px;
  padding-left: 1px !important;
  transition: 0.4s ease all;
}

.share-button:hover {
  background: #ccc;
}

.iti {
  box-sizing: border-box;
}
.iti .iti__flag-container .dropdown-menu.country-dropdown {
  display: none;
}
.iti .iti__flag-container.open .dropdown-menu.country-dropdown {
  display: block;
  inset: 100% auto auto 0px;
  transform: translateY(0px);
  position: absolute;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
.iti .tooltip {
  display: block;
  pointer-events: none;
  position: absolute;
  z-index: 1070;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
}
.iti .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.iti .tooltip .arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.iti .tooltip.show {
  opacity: 0.9;
}
.iti .bs-tooltip-top {
  padding: 0.4rem 0;
}
.iti .bs-tooltip-top.tooltip .arrow:before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.iti .bs-tooltip-top .arrow {
  bottom: 0;
}
.iti .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  width: 100% !important;
  padding-top: 7px;
  padding-bottom: 7px;
  letter-spacing: 1px;
  font-size: 18px;
  border: 1px solid #ced4da !important;
}

.iti.iti--allow-dropdown {
  width: 100% !important;
}

.iti input.form-control, .iti input[type=text].form-control, .iti input[type=tel].form-control {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  height: 30px !important;
}