

.ratings {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    color: #b1b1b1;
    overflow: hidden;
}
.full-stars {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    color: #ed9720;
}
.empty-stars:before, .full-stars:before {
    content:"\2605  \2605  \2605  \2605  \2605";
    font-size: 12pt;
}
.empty-stars:before {
    -webkit-text-stroke: 1px #b1b1b1;
}
.full-stars:before {
    -webkit-text-stroke: 1px #ed9720;
}
/* Webkit-text-stroke is not supported on firefox or IE */

/* Firefox */
 @-moz-document url-prefix() {
    .full-stars {
        color: #ed9720;
    }
}
/* IE */
//  <!--[if IE]>
.full-stars {
    color: #ed9720;
}
// <![endif]-->